<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <b-card>
          <b-card-text>
            <DxGrid
              title="Manufacturers"
              canSelect="none"
              :data-source="dataSource"
              @on-delete="(v) => onDelete(v)"
              @on-open-modal="(v) => onOpenModal(v)"
              @on-update-cancel="(v) => loadManufacturers()"
            >
              <template #columns>
                <DxColumn data-field="name" caption="Name" />
                <DxColumn data-field="website" caption="Website" />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      hide-footer
      v-model="modalShow"
      centered
      @hide="closeModal()"
      size="lg"
    >
      <validation-observer ref="refManufacturer" #default="{ invalid }">
        <b-row>
          <b-col cols="12" md="6" v-if="imagePreview">
            <b-form-group label="Preview">
              <b-img center thumbnail fluid :src="imagePreview" />
            </b-form-group>
          </b-col>

          <b-col cols="12" :md="imagePreview ? '6' : '12'">
            <b-row>
              <b-col>
                <b-form-group label="Image">
                  <b-form-file
                    ref="imageRef"
                    accept="image/*"
                    v-model="imageUpload"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <validation-provider
                  #default="{ errors }"
                  name="Manufacturer Name"
                  vid="v-name"
                  rules="required"
                >
                  <b-form-group label="Name" label-for="v-name">
                    <b-form-input
                      :class="errors.length > 0 ? 'is-invalid' : null"
                      v-model="formData.name"
                      name="name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Website" label-for="v-website">
                  <b-form-input v-model="formData.website"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="text-right">
            <b-form-group>
              <b-button
                size="sm"
                @click="closeModal()"
                variant="danger"
                class="bg-darken-4 mr-1"
              >
                Cancel
              </b-button>
              <b-button
                :disabled="invalid"
                size="sm"
                @click="onSubmitManufacturer()"
                variant="primary"
                class="bg-darken-4"
              >
                Save
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
  </section>
</template>

<script>
import manufacturersApi from '@api/manufacturers';
import { getImage } from '@/global-functions';
import { required } from '@validations';
import { queryParameters } from '@/schema';

export default {
  name: 'ManufacturerPage',
  components: {},
  data: () => ({
    required,
    modalShow: false,
    dataSource: [],

    imageUpload: null,
    imagePreview: null,
    imageName: null,
    imageExt: null,

    formData: {
      id: 0,
      name: null,
      file: null,
      website: null,
      thumbnailbase64: null,
    },
  }),
  watch: {
    imageUpload(e) {
      if (e) {
        const reader = new window.FileReader();
        reader.readAsDataURL(e);
        reader.onload = () => {
          this.imageExt = e.name.split('.')[1];
          this.imageName = e.name;
          this.imagePreview = reader.result;

          this.formData.file = e.name;
          this.formData.thumbnailbase64 = reader.result;
        };
      }
    },
  },
  mounted() {
    this.loadManufacturers();
  },
  methods: {
    loadManufacturers() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      manufacturersApi
        .list(params)
        .then(({ data }) => {
          if (data != null) {
            this.dataSource = data;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmitManufacturer() {
      const closeModalLoadManufacturers = () => {
        this.toastConfig();
        this.loadManufacturers();
        this.closeModal();
      };

      this.$refs.refManufacturer.validate().then((success) => {
        if (success) {
          let app;

          if (this.formData.id) {
            app = manufacturersApi.update(this.formData);
          } else {
            app = manufacturersApi.add(this.formData);
          }

          app
            .then(({ data }) => {
              closeModalLoadManufacturers();
            })
            .catch(() => {
              this.toastConfig('error');
            })
            .finally(() => {
              //
            });
        }
      });
    },
    onOpenModal(event) {
      this.modalShow = event.value;

      if (event.name === 'edit') {
        this.onEdit(event.data);
      }
    },
    onEdit(data) {
      this.$nextTick(() => {
        const getImageData = {
          model: 'manufacturers',
          id: data.id,
          thumbnail: data.file,
        };

        data.url = getImage(getImageData);

        console.log(data);

        if (data.thumbnailbase64 !== '') {
          this.imagePreview = data.thumbnailbase64;
        } else {
          this.imagePreview = data.url;
        }

        this.formData = Object.assign({}, data);
      });
    },
    onDelete(event) {
      manufacturersApi
        .delete(event)
        .then(() => {
          this.loadManufacturers();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    closeModal() {
      this.modalShow = false;
      const defaultForm = {
        id: 0,
        name: null,
        file: null,
        website: null,
        thumbnailbase64: null,
      };

      this.$nextTick(() => {
        this.imagePreview = null;
        this.formData = Object.assign({}, defaultForm);
      });
    },
  },
};
</script>

<style></style>
